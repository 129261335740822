<template >
  <div class="container" ref="container" @scroll.passive="scroll">
    <div class="header-box">
      <div class="avatar-box">
        <img
          ref="avatar"
          class="avatar"
          alt=""
          :src="avatarUrl"
          v-if="avatarUrl"
        />
      </div>
      <div class="info-box">
        <div class="div-nickname">
          <span class="nickname">{{
            feedUserInfo ? feedUserInfo.sNickName : ""
          }}</span>
        </div>
        <div class="fans">
          <span @click="openAttentionList">关注</span
          ><span @click="openAttentionList">
            {{ feedUserInfo ? feedUserInfo.iFollower : 0 }}</span
          >
          <span @click="openFansList" class="like">粉丝</span
          ><span @click="openFansList">
            {{ feedUserInfo ? feedUserInfo.iFans : 0 }}</span
          >
        </div>
      </div>
      <div class="intro">{{ feedUserInfo ? feedUserInfo.sProfile : "" }}</div>

      <div class="info-text" @click="openNativeInfoPage">资料</div>
    </div>
    <div class="tab-box">
      <div class="tab tab-video" @click="selectTab(0)">
        <div class="tab-text">精选作品</div>
        <div class="tab-indicator" v-show="tabIndex === 0"></div>
      </div>
      <div class="tab tab-product" @click="selectTab(1)">
        <div class="tab-text">产品与服务</div>
        <div class="tab-indicator" v-show="tabIndex === 1"></div>
      </div>
    </div>
    <div class="video-list" v-if="tabIndex === 0">
      <div class="no-data" v-if="videoList.length === 0">
        <span>暂无数据</span>
      </div>
      <VideoItem
        class="video-item"
        v-for="(item, index) in videoList"
        :key="index"
        :videoInfo="item"
      >
      </VideoItem>
    </div>

    <div class="product-list" v-if="tabIndex === 1">
      <div class="no-data" v-if="productList.length === 0">
        <span>暂无数据</span>
      </div>
      <div style="padding: 9px">
        <ProductItem
          class="product-item"
          v-for="(item, index) in productList"
          :key="index"
          :productInfo="item"
        >
        </ProductItem>
      </div>
    </div>
    <div
      class="attention-box"
      v-if="feedUserInfo && feedUserInfo.iAccountId > 0"
    >
      <div
        :class="[isAttention ? 'attention-no' : 'attention-yes']"
        @click="handleAttention"
      >
        <p class="attention-text">{{ attentionText }}</p>
      </div>
    </div>
  </div>
</template>
<script>
import VideoItem from "@/components/user/VideoItem";
import ProductItem from "@/components/user/ProductItem";
import { debounce,userArray } from "@/utils/utils.js";
export default {
  data() {
    return {
      avatarUrl: "",
      tabIndex: 0,
      userId: 0,
      feedUserInfo: null,
      stUserInfo: null, //自己的用户信息
      videoList: [],
      productList: [],
      isAttention: 0,
      videoPageIndex: 1,
      productPageIndex: 1,
      hasMoreVideo: false,
      hasMoreProduct: false,
      loading: false,
    };
  },
  components: { VideoItem, ProductItem },
  computed: {
    attentionText() {
      return this.isAttention ? "已关注" : "+关注";
    },
    isSelf() {
      return (
        this.stUserInfo != null &&
        this.feedUserInfo != null &&
        this.stUserInfo.AID === this.feedUserInfo.iAccountId &&
        this.stUserInfo.AID > 0
      );
    },
  },
  methods: {
    openNativeInfoPage() {
      beacon.openUserDetail(this.feedUserInfo, () => {});
    },
    openAttentionList: debounce(function () {
      this.toAttentionListPage(0);
    }),
    openFansList: debounce(function () {
      this.toAttentionListPage(1);
    }),

    toAttentionListPage(i) {
      this.$toNewPage("/friends", {
        id: this.feedUserInfo.iAccountId,
        index: i,
      });
    },

    async handleAttention() {
      if (!this.stUserInfo || this.stUserInfo.AID <= 0) {
        let login = new Promise((resolve, reject) => {
          beacon.openLoginPanel({}, (data) => {
            resolve(data);
          });
        });
        return;
      }
      if (this.feedUserInfo) {
        const iAction = this.feedUserInfo.isAttention ? 0 : 1;
        const params = {
          userId: this.stUserInfo ? this.stUserInfo.AID : 0,
          attentionUserId: this.feedUserInfo.iAccountId,
          isAttention: iAction,
        };
        const data = await this.API.appSlider.clickFocusAutor(params);
        if (data && data.status === 200) {
          console.log(this.isSelf);
          if (iAction == 1) {
            this.feedUserInfo.isAttention = 1;
            this.feedUserInfo.iFans += 1;

            if (this.isSelf) {
              //如果是自己关注自己
              this.feedUserInfo.iFollower += 1;
            }
          } else {
            this.feedUserInfo.isAttention = 0;
            this.feedUserInfo.iFans -= 1;
            if (this.isSelf) {
              //如果是自己关注自己
              this.feedUserInfo.iFollower -= 1;
            }
          }
          this.isAttention = this.feedUserInfo.isAttention === 1;
        }
      }
    },
    selectTab(index) {
      this.tabIndex = index;
    },
    getUserInfo() {
      return new Promise((resolve, reject) => {
        beacon.getAccountInfo({}, (accountInfo) => {
          resolve(accountInfo);
        });
      });
    },
    async getFeedUserInfo() {
      const stUserInfo = {
        vGUID: [],
        sDUA: "",
        iAccountId: this.stUserInfo ? this.stUserInfo.AID : 0, // 灯塔互动APP登陆后才有的id
      };

      const params = {
        stUserInfo: stUserInfo,
        iOtherAccountId: this.userId,
      };
      const data = await this.API.appSlider.getFeedUserInfo(params);
      if (data.status === 200) {
        this.feedUserInfo = data.data.data;
        if (this.feedUserInfo) {
          if (this.feedUserInfo.sFaceUrl) {
            this.avatarUrl = this.feedUserInfo.sFaceUrl;
          }
          this.isAttention = this.feedUserInfo.isAttention === 1;
        }
      }
    },
    async getTalentGoodVideoList() {
      const stUserInfo = {
        vGUID: [],
        sDUA: "",
        iAccountId: this.stUserInfo ? this.stUserInfo.AID : 0, // 灯塔互动APP登陆后才有的id
      };

      const params = {
        iPageNum: this.videoPageIndex,
        iUserId: this.userId,
        stUserInfo: stUserInfo,
      };
      const data = await this.API.appSlider.getTalentGoodVideoList(params);
      this.loading = false;
      if (data.status === 200) {
        if (data.data.vInteractiveVideo.length == 0) {
          return;
        }
        this.videoList = this.videoList.concat(data.data.vInteractiveVideo);
        this.hasMoreVideo = this.videoList.length < data.data.iTotalPageNum;
      } else {
        if (this.videoPageIndex > 1) {
          this.videoPageIndex--;
        }
        console.error(data);
      }
    },
    async getTalentAppreciationList() {
      const stUserInfo = {
        vGUID: [],
        iAccountId: this.stUserInfo ? this.stUserInfo.AID : 0, // 灯塔互动APP登陆后才有的id
      };

      const params = {
        iPageNum: this.productPageIndex,
        iUserId: this.userId,
        stUserInfo: stUserInfo,
      };
      const data = await this.API.appSlider.getTalentAppreciationList(params);
      this.loading = false;
      if (data.status === 200) {
        if (data.data.vInteractiveVideo.length == 0) {
          return;
        }
        this.productList = this.productList.concat(data.data.vInteractiveVideo);
        this.hasMoreProduct = this.productList.length < data.data.iTotalPageNum;
      } else {
        if (this.productPageIndex > 1) {
          this.productPageIndex--;
        }
        console.error(data);
      }
    },
    scroll() {
      //变量scrollTop是滚动条滚动时，距离顶部的距离
      let scrollTop =
        document.documentElement.scrollTop || document.body.scrollTop;
      let windowHeight =
        document.documentElement.clientHeight || document.body.clientHeight;
      let scrollHeight =
        document.documentElement.scrollHeight || document.body.scrollHeight;

      if (scrollTop + windowHeight > scrollHeight - 10) {
        if (this.tabIndex == 0 && this.hasMoreVideo && !this.loading) {
          this.loading = true;
          this.videoPageIndex++;
          this.getTalentGoodVideoList();
        } else if (this.tabIndex == 1 && this.hasMoreProduct && !this.loading) {
          this.loading = true;
          this.productPageIndex++;
          this.getTalentAppreciationList();
        }
      }
    },
    listenMobile(funcName) {
      lisenNative(funcName, async () => {
        let userInfo = await this.getUserInfo();
        // if (
        //   (!this.stUserInfo || this.stUserInfo.AID <= 0) &&
        //   userInfo &&
        //   userInfo.AID > 0
        // ) {
        this.stUserInfo = userInfo;
        this.resetData();
        this.requestData();
        // }
      });
    },
    resetData() {
      this.videoPageIndex = 1;
      this.productPageIndex = 1;
      this.hasMoreVideo = false;
      this.hasMoreProduct = false;
      this.loading = false;
      this.videoList = [];
      this.productList = [];
    },
    async requestData() {
      await this.getFeedUserInfo();
      await this.getTalentGoodVideoList();
      await this.getTalentAppreciationList();
    },
    initBackButton() {
      beacon.setWebViewTitleBar(userArray, () => {});
    },
  },

  async mounted() {
    this.initBackButton();
    if (this.$route.query.id) {
      this.userId = this.$route.query.id;
    }
    this.stUserInfo = await this.getUserInfo();
    this.requestData();
    this.listenMobile("webviewShow");
    this.listenMobile("loginSuccess");
    window.addEventListener("scroll", this.scroll);
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.scroll);
  },
};
</script>
<style lang="css" scoped>
.container {
  height: 100%;
}
img {
  touch-callout: none;
  -webkit-touch-callout: none;
  user-select: none;
  -webkit-user-select: none;
}
.header-box {
  width: 100%;
  height: 175px;
  background: url("/assets/images/teacher_header_bg.png") no-repeat;
  background-size: cover;
  color: white;
}
.avatar-box {
  position: absolute;
  width: 44px;
  height: 44px;
  top: 60px;
  left: 25px;
  border-radius: 50px;
  background-color: rgba(170, 170, 170, 0.363);
}
.avatar {
  width: 100%;
  height: 100%;
  padding: 0px;
  margin: 0px;
  border-radius: 50px;
}
.info-box {
  top: 60px;
  position: absolute;
  left: 84px;
}
.info-text {
  top: 52px;
  position: absolute;
  right: 14px;
  height: 36px;
  font-size: 14px;
  font-weight: bold;
  color: #ffffff;
  line-height: 36px;
  padding-left: 20px;
}
.div-nickname {
  height: 26px;
}
.nickname {
  line-height: 26px;
  font-size: 18px;
  font-weight: 700;
}
.fans {
  margin-top: 2px;
  font-size: 12px;
}
.like {
  display: inline-block;
  margin-inline-start: 30px;
}
.intro {
  position: absolute;
  left: 25px;
  top: 120px;
  font-size: 12px;
  font-weight: 400;
  color: #fff1e9;
  line-height: 17px;
  padding-right: 25px;
}
.tab-box {
  height: 57px;
  width: 100%;
  top: 0px;
  background: white;
}
.tab {
  text-align: center;
  height: 57px;
  line-height: 57px;
}

.tab-video {
  width: 50%;
  height: 100%;
  float: left;
}

.tab-product {
  width: 50%;
  float: right;
}

.tab-text {
  float: left;
  width: 100%;
}
.tab-indicator {
  width: 54px;
  height: 3px;
  background: #ff8f1f;
  opacity: 1;
  margin-left: auto;
  margin-right: auto;
  margin-top: 54px;
}
.video-list {
  display: flex;
  display: -webkit-flex; /* Safari */
  -webkit-flex-wrap: wrap; /* Safari 6.1+ */
  flex-wrap: wrap;
  padding-bottom: 100px;
}
.video-item {
  width: calc(33.3% - 2px);
  height: 192px;
  margin-bottom: 2px;
  margin-right: 2px;
}
.video-item:nth-of-type(3n + 3) {
  margin-right: 0px;
}
.product-list {
  padding: 0px;
  padding-bottom: 100px;
}
.attention-box {
  position: fixed;
  background: red;
  bottom: 20px;
  width: 100%;
  height: 44px;
  padding: 0 16px;
  background: transparent;
}
.attention-no {
  background: white;
  text-align: center;
  border: 1.5px solid #2525259a;
  border-radius: 5px;
  color: #2525259a;
  line-height: 44px;
}

.attention-yes {
  background: white;
  text-align: center;
  border: 1.5px solid #fd7736;
  border-radius: 5px;
  color: #fd7736;
  line-height: 44px;
}
.no-data {
  height: 200px;
  width: 100%;
  line-height: 200px;
  text-align: center;
  color: #2525259a;
}
</style>