<template>
  <div class="content-box" @click="toBuyPage()">
    <div class="left">
      <div class="title">
        <span class="span-title">{{ productInfo.productName }}</span>
      </div>
      <div class="intro">
        <span class="span-intro">{{ productInfo.productIntroduce }}</span>
      </div>
    </div>
    <div class="right">
      <div class="buy">买入</div>
      <img class="coin" src="/assets/images/interact_video/icon_coin.png" />
    </div>
  </div>
</template>
<script>
import { debounce} from "@/utils/utils.js";
export default {
  props: ["productInfo"],
  data() {
    return {};
  },
  methods: {
    toBuyPage:debounce(function(){
      console.log("productInfo", this.productInfo);
      this.title = this.productInfo;
      if (this.productInfo.brokerUrl) {
        let url;
        let param = "&dt_page_type=11&webviewType=userActivitesType";
        if (this.productInfo.brokerUrl.indexOf("?") > 0) {
          url = this.productInfo.brokerUrl + "&" + param;
        } else {
          url = this.productInfo.brokerUrl + "?" + param;
        }
        location.href = url;
      } else {
        alert("链接为空");
      }
    }),
  },
  mounted() {
  },
};
</script>
<style lang="css" scoped>
img{
  touch-callout: none;
  -webkit-touch-callout: none;
  user-select: none;
  -webkit-user-select: none;
}
.content-box {
  box-sizing: border-box;
  height: 111px;
  background: linear-gradient(180deg, #fffdfc 0%, #fffaf7 100%);
  box-shadow: 0px 4px 6px 0px rgba(255, 112, 11, 0);
  border-radius: 4px;
  opacity: 1;
  border: 1px solid #fbede7;
  margin-bottom: 9px;
}

.coin {
  width: 90px;
  height: 70px;
  position: absolute;
  right: 0;
  top: 0;
  margin-right: 12px;
  margin-top: 12px;
}
.buy {
  float: right;
  right: 20px;
  width: 88px;
  height: 30px;
  background: #f78f1e;
  border-radius: 2px 2px 2px 2px;
  opacity: 1;
  color: white;
  text-align: center;
  line-height: 30px;
  position: absolute;
  bottom: 10px;
  right: 12px;
}

.left {
  float: left;
  width: 70%;
  height: 100%;
  position: relative;
}
.right {
  float: right;
  width: 30%;
  position: relative;
}
.title {
  top: 19px;
  left: 16px;
}
.span-title {
  color: #606060;
  font-size: 18px;
  font-weight: bold;
  position: absolute;
  top: 20px;
  left: 16px;
}
.intro {
  left: 16px;
}
.span-intro {
  color: #6d6d6d;
  font-size: 12px;
  position: absolute;
  bottom: 12px;
  left: 16px;
}
</style>