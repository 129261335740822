<template>
  <div class="content" ref="content" @click="openVideo()" >
    <img class="cover" ref="cover" :src="videoInfo.sPicUrl" alt="" />

    <div class="shadow">
      <div class="video-info">
        <img
          class="icon like"
          src="/assets/images/interact_video/ico_zan_off.png"
        />
        <span>{{ videoInfo.iLikeNum }}</span>
        <img
          class="icon share"
          src="/assets/images/interact_video/ico_share.png"
        />
        <span>{{ videoInfo.iShareNum }}</span>
      </div>
    </div>
  </div>
</template>
<script>
import { debounce} from "@/utils/utils.js";
export default {
  name: "VideoItem",
  props: ["videoInfo"],
  data() {
    return {
      likeNum: 0,
      shareNum: 0,
    };
  },
  methods: {
    openVideo:debounce(function(){
      if (this.videoInfo.iId) {
        this.$toNewPage("/dengYingSingle",{id:this.videoInfo.iId},true);
      }
    }),
  },
};
</script>
<style lang="css" scoped>
img{
  touch-callout: none;
  -webkit-touch-callout: none;
  user-select: none;
  -webkit-user-select: none;
}
.content {
  background: rgba(0, 0, 0, 0.4);
  position: relative;
  background-size: cover;
  overflow: hidden;
}
.shadow {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 40px;
  background: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0) 5%,
    rgba(0, 0, 0, 0.3) 99%
  );
  position: absolute;
}
.cover {
  width: 100%;
}
.icon {
  width: 12px;
  height: 12px;
}
.video-info {
  width: fit-content;
  position: absolute;
  bottom: 2px;
  display: flex;
  align-items: center;
}
span {
  margin-inline-start: 2px;
  color: white;
  font-size: 7px;
}
.share {
  margin-inline-start: 10px;
}
.like {
  margin-inline-start: 6px;
}
img {
  margin: auto;
}
</style>